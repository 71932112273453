import React from "react";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Home from "./Home";
import Projects from "./Projects";
import About from "./About";
import ParticlesBg from "./backgroundParticles";
import Toggle from "./ButtonToggle";
import Skills from "./skills";
import ProjectDetail from "./ProjectDetail";

function App() {
  const [isAnimated, setAnimated] = useState(true);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Dominik von Allmen</title>
      </Helmet>
      ;
      <div
        style={{ backgroundColor: "#121212", minHeight: "100vh", zIndex: "-1" }}
      >
        {isAnimated && <ParticlesBg />}
        <Toggle isAnimated={isAnimated} setAnimated={setAnimated} />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/projects/:slug" element={<ProjectDetail />} />
        </Routes>
      </div>
    </HelmetProvider>
  );
}

export default App;
