import React from "react";
import { useState, useEffect } from "react";
import { motion as m } from "framer-motion";
import { useParams } from "react-router-dom";
import { GraphQLClient, gql } from "graphql-request";
import Button from "./button";
import "./projectDetail.css";
import ByPrj from "./byProjects";

const graphcms = new GraphQLClient(
  "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clee6i6jp5cmx01uodfhw53k2/master"
);

const QUERY = gql`
  query getProject($slug: String!) {
    posts(where: { slug: $slug }) {
      id
      title
      datePublished
      slug
      coverPhoto {
        id
        url
      }
      content {
        html
      }
      seos {
        id
        metaTitle
        metaDescription
        tags
        ogImage {
          id
        }
      }
    }
  }
`;

const tileWidth =
  window.innerWidth * 0.8 < 330
    ? 330
    : window.innerWidth * 0.8 > 1000
    ? 1000
    : window.innerWidth * 0.8;

function ProjectDetail() {
  const [posts, setPosts] = useState(null);
  const { slug } = useParams();
  const mobileView = window.innerWidth < window.innerHeight;

  useEffect(() => {
    async function fetchPosts() {
      const { posts } = await graphcms.request(QUERY, { slug });
      console.log(posts);
      setPosts(posts);
    }
    fetchPosts();
  }, [slug]);

  if (!posts) {
    return <div>Loading...</div>;
  }

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <ByPrj />
      <Button />
      <div
        style={{
          position: "absolute",
          marginTop: "90px",
          left: `calc(50% - ${tileWidth / 2}px)`,
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
          gap: "1rem",
          backgroundColor: "transparent",
        }}
      >
        {posts.map((post) => (
          <div
            key={post.id}
            className="projectTile2"
            style={{
              marginTop: "90px",
              width: `calc(${tileWidth}px - 2rem)`,
              left: `calc(${tileWidth / 2}px)`,
              transform: "translateX(-50%)",
              background:
                "linear-gradient(to top left, rgb(31, 31, 31, 0.46), rgb(61, 61, 61, 0.46))",
              backdropFilter: "blur(5px)",
              padding: mobileView ? "1rem" : "2rem",
              paddingTop: "1rem",
              borderRadius: "50px",
              color: "#CCCCCC",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ fontSize: "1.5rem" }}>{post.title}</p>
              <p style={{ fontStyle: "italic" }}>
                {new Date(post.datePublished).toLocaleDateString()}
              </p>
            </span>
            {post.coverPhoto && (
              <img
                src={post.coverPhoto.url}
                alt=""
                width="100%"
                style={{ borderRadius: "25px", marginBottom: "15px" }}
              />
            )}
            {post.seos && post.seos.length && post.seos[0].tags && (
              <div>
                {post.seos[0].tags.split(",").map((tag, index) => (
                  <span
                    key={index}
                    style={{
                      backgroundImage:
                        "radial-gradient(circle, rgba(221,174,238, 0.75) 0%, rgba(148,233,231, 0.6) 100%)",
                      color: "#121212",
                      borderRadius: "10px",
                      padding: "5px 10px",
                      margin: "5px",
                      fontSize: "0.8rem",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    {tag.trim()}
                  </span>
                ))}
                <div
                  style={{ marginTop: "1rem", color: "#CCCCCC", width: "100%" }}
                  dangerouslySetInnerHTML={{ __html: post.content.html }}
                />
              </div>
            )}
          </div>
        ))}
        <div
          style={{
            height: "50px",
            width: "100%",
            backgroundColor: "",
          }}
        ></div>
      </div>
    </m.div>
  );
}

export default ProjectDetail;
