import React from "react";
import "./bubble.css";

import { Linkedin } from "@styled-icons/boxicons-logos/Linkedin";
import { Mail } from "@styled-icons/entypo/Mail";

const tileWidth =
  window.innerWidth * 0.8 < 330
    ? 330
    : window.innerWidth * 0.8 > 850
    ? 850
    : window.innerWidth * 0.8;

function Bubbles() {
  function handleClick2() {
    window.open("https://www.linkedin.com/in/dominik-von-allmen-479b7b88");
  }
  function handleClick1() {
    window.open("mailto:dominik.v.allmen@gmx.ch", "_self");
  }

  return (
    <div
      style={{
        height: "350px",
        width: tileWidth,
        position: "relative",
        display: "flex",
        left: "0",
      }}
    >
      <div
        className="bubbleR"
        style={{
          width: "125px",
          height: "125px",
          opacity: 1,
          borderRadius: "50%",
          position: "absolute",
          left: `calc(50% - ${125 / 2}px - 80px)`,
          top: `calc(65% - ${125 / 2}px)`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background:
            "radial-gradient(circle, rgba(221,174,238, 0.6) 0%, rgba(148,233,231, 0.6) 100%)",
          backdropFilter: "blur(5px)",
          cursor: "pointer",
        }}
        onClick={handleClick1}
      >
        <Mail className="iconR" width="50px" height="50px" />
      </div>
      <div
        className="bubbleL"
        style={{
          width: "125px",
          height: "125px",
          opacity: 1,
          borderRadius: "50%",
          position: "absolute",
          left: `calc(50% - ${125 / 2}px + 80px)`,
          top: `calc(65% - ${125 / 2}px)`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background:
            "radial-gradient(circle, rgba(221,174,238, 0.6) 0%, rgba(148,233,231, 0.6) 100%)",
          backdropFilter: "blur(5px)",
          cursor: "pointer",
        }}
        onClick={handleClick2}
      >
        <Linkedin className="iconL" width="50px" height="50px" />
      </div>
    </div>
  );
}

export default Bubbles;
