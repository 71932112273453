function Point({ color, delay }) {
  const mobileView = window.innerWidth < window.innerHeight;
  return (
    <div
      className="point"
      style={{
        width: mobileView ? "25px" : "60px",
        height: "25px",
        borderRadius: "100px",
        backgroundColor: color,
        marginLeft: mobileView ? "10px" : "15px",
        opacity: "0",
        animation: "spawn 4s 1",
        animationFillMode: "forwards",
        animationDelay: delay,
        transition: "transform 2.5s ease-in",
      }}
    ></div>
  );
}

export default Point;
