import React, { useState, useEffect } from "react";
import { GraphQLClient, gql } from "graphql-request";
import Chart from "./chart";

const graphcms = new GraphQLClient(
  "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clee6i6jp5cmx01uodfhw53k2/master"
);

const QUERY = gql`
  {
    skills {
      skillName
      expertise
      category
    }
  }
`;

function OtherSkills() {
  const [skills, setSkills] = useState([]);
  const mobileVersion = window.innerWidth > 1000;

  useEffect(() => {
    async function fetchSkills() {
      const data = await graphcms.request(QUERY);
      const techSkills = data.skills.filter(
        (skill) => skill.category === "other"
      );
      const sortedSkills = techSkills.sort((a, b) => b.expertise - a.expertise);
      setSkills(sortedSkills);
    }
    fetchSkills();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            height: "50px",
            width: "100%",
            maxWidth: "575px",
            alignItems: "center",
            justifyContent: "center",
            background:
              "linear-gradient(to top left, rgb(31, 31, 31, 0.46), rgb(61, 61, 61, 0.46))",
            backdropFilter: "blur(5px)",
            borderRadius: "100px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <h1 style={{ color: "#CCCCCC" }}>Weitere Skills</h1>
        </div>
        {skills.map((skill) => (
          <Chart
            key={skill.skillName}
            skillName={skill.skillName}
            expertise={skill.expertise}
          />
        ))}
      </div>
    </div>
  );
}

export default OtherSkills;
