import React from "react";
import profilePic from "./res/profile_trans.png";
import gradient from "./res/grad.png";
import "./Visualizer.css";

const Visualizer = () => {
  const [modi, setModi] = React.useState(0.08);
  const [waves, setWaves] = React.useState([]);

  React.useEffect(() => {
    const waveArray = [];
    for (let i = 25; i < 45; i++) {
      waveArray.push({
        id: i,
        height: Math.floor(Math.random() + i * 5.5),
        width: Math.floor(Math.random() + i * 2),
        angle: i * 12,
      });
    }
    setWaves(waveArray);
  }, []);

  return (
    <div className="visualizer">
      <div
        className="circle-center"
        onMouseEnter={() => setModi(0.5)}
        onMouseLeave={() => setModi(0.08)}
      >
        <img className="picture" src={gradient} alt="bg" />
        <img className="picture" src={profilePic} alt="bild" />
      </div>
      {waves.map((wave) => (
        <Wave key={wave.id} modi={modi} {...wave} />
      ))}
    </div>
  );
};

const Wave = ({ id, height, width, angle, modi }) => {
  const animationDuration = id * modi + "s";

  return (
    <div
      className="wave"
      style={{
        height: height + "px",
        width: width + "px",
        transform: `rotate(${angle}deg)`,
        animation: `move-wave ${animationDuration} linear infinite`,
      }}
    />
  );
};

export default Visualizer;
