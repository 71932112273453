import React from "react";
import { useState, useEffect } from "react";
import { motion as m } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Button from "./button";
import { GraphQLClient, gql } from "graphql-request";
import "./project.css";
import ByPrj from "./byProjects";

const graphcms = new GraphQLClient(
  "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clee6i6jp5cmx01uodfhw53k2/master"
);

const QUERY = gql`
  {
    posts {
      id
      title
      datePublished
      slug
      coverPhoto {
        id
        url
      }
      content {
        html
      }
      seos {
        id
        metaTitle
        metaDescription
        tags
        ogImage {
          id
        }
      }
    }
  }
`;

const tileWidth =
  window.innerWidth * 0.8 < 330
    ? 330
    : window.innerWidth * 0.8 > 1000
    ? 1000
    : window.innerWidth * 0.8;

function mobileView() {
  return window.innerWidth < window.innerHeight;
}

function Projects() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPosts() {
      const { posts } = await graphcms.request(QUERY);
      setPosts(posts);
    }
    fetchPosts();
  }, []);

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <ByPrj />
      <Button />
      <div
        style={{
          position: "absolute",
          marginTop: "150px",
          width: tileWidth,
          left: `calc(50% - ${tileWidth / 2}px)`,
          display: "grid",
          gridTemplateColumns: mobileView()
            ? "repeat(1, 1fr)"
            : "repeat(3, 1fr)",
          gap: "1rem",
          backgroundColor: "transparent",
        }}
      >
        {posts.map((post) => (
          <div
            className="projectTile"
            key={post.id}
            onClick={() => navigate(`/projects/${post.slug}`)}
            style={{
              background:
                "linear-gradient(to top left, rgb(31, 31, 31, 0.46), rgb(61, 61, 61, 0.46))",
              backdropFilter: "blur(5px)",
              padding: "1.25rem",
              cursor: "pointer",
              borderRadius: "50px",
              color: "#CCCCCC",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ fontSize: "1.5rem" }}>{post.title}</p>
              <p style={{ fontStyle: "italic" }}>
                {new Date(post.datePublished).toLocaleDateString()}
              </p>
            </span>
            {post.coverPhoto && (
              <img
                src={post.coverPhoto.url}
                alt=""
                width="100%"
                style={{ borderRadius: "25px" }}
              />
            )}
            {post.seos && post.seos.length && post.seos[0].metaTitle && (
              <p style={{ fontSize: "1rem" }}>{post.seos[0].metaDescription}</p>
            )}
            {post.seos && post.seos.length && post.seos[0].tags && (
              <div>
                {post.seos[0].tags.split(",").map((tag, index) => (
                  <span
                    key={index}
                    style={{
                      backgroundImage:
                        "radial-gradient(circle, rgba(221,174,238, 0.75) 0%, rgba(148,233,231, 0.6) 100%)",
                      color: "#121212",
                      borderRadius: "10px",
                      padding: "5px 10px",
                      marginLeft: "7.5px",
                      fontSize: "0.8rem",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    {tag.trim()}
                  </span>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </m.div>
  );
}

export default Projects;
