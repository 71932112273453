import React from "react";
import "./by.css";

import { HeartFill } from "@styled-icons/bootstrap/HeartFill";
import { Coffee } from "@styled-icons/material/Coffee";

function By() {
  return (
    <div
      style={{
        width: "150px",
        height: "50px",
        left: `calc(50% - ${150 / 2}px)`,
        marginTop: "20px",
        opacity: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "100px",
        position: "absolute",
        userSelect: "none",
        background:
          "radial-gradient(circle, rgba(221,174,238, 0.75) 0%, rgba(148,233,231, 0.75) 100%)",
        backdropFilter: "blur(5px)",
        color: "#121212",
      }}
    >
      <p style={{ margin: "0", marginTop: "0px" }}>
        Built with{" "}
        <HeartFill className="iconheart" width="16px" height="16px" />
      </p>
      <p style={{ margin: "0" }}>
        and lots of <Coffee className="iconcoffee" width="18px" height="18px" />
      </p>
    </div>
  );
}

export default By;
