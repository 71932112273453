import React from "react";
import "./chart.css";
import Point from "./point";

const numPoints = 5;

function Chart({ skillName, expertise }) {
  const colors = generateGradient(numPoints);
  const mobileView = window.innerWidth < window.innerHeight;
  const points = [];

  /*for (let i = 0; i < expertise; i++) {
    points.push(
      <Point key={i} color={colors[i]} id={`point-${i}`} delay={`${i * 0.1}`} />
    );
  }*/
  for (let i = 0; i < expertise; i++) {
    points.push(
      <Point
        key={i}
        color={colors[i]}
        id={`point-${i}`}
        delay={`${i * 0.46}s`}
      />
    );
  }

  return (
    <div className="chartBoxOuter" style={{ maxWidth: "575px" }}>
      <div
        className="chartBoxInner"
        style={{
          position: "relative",
          marginTop: "15px",
          display: "flex",
        }}
      >
        <div
          className="base"
          style={{
            position: "relative",
            borderRadius: "100px",
            left: "0px",
            height: "50px",
            width: mobileView ? "130px" : "200px",
            maxWidth: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background:
              "linear-gradient(to top left, rgb(31, 31, 31, 0.46), rgb(61, 61, 61, 0.46))",
            backdropFilter: "blur(5px)",
          }}
        >
          <h3
            className="skillName"
            style={{
              fontSize: "0.8rem",
              position: "relative",
              color: "#CCCCCC",
              padding: "5%",
              userSelect: "none",
            }}
          >
            {skillName}
          </h3>
        </div>
        <div className="chartPoints">{points}</div>
      </div>
    </div>
  );
}

export default Chart;

function generateGradient(numPoints) {
  const startColor = [183, 253, 254, 0.8];
  const endColor = [164, 54, 232, 0.8];
  const step = 1 / (numPoints - 1);
  const colors = [];

  for (let i = 0; i < numPoints; i++) {
    const color = [
      Math.round(startColor[0] + i * (endColor[0] - startColor[0]) * step),
      Math.round(startColor[1] + i * (endColor[1] - startColor[1]) * step),
      Math.round(startColor[2] + i * (endColor[2] - startColor[2]) * step),
    ];

    colors.push(`rgb(${color.join(",")})`);
  }

  return colors;
}
