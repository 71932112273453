// @ts-ignore
import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Radial.css";
import MenuItem from "./MenuItem.tsx";
import Visualizer from "./Visualizer";

import { Folder } from "@styled-icons/entypo/Folder";
import { PersonCircle } from "@styled-icons/bootstrap/PersonCircle";
import { StatsBars } from "@styled-icons/icomoon/StatsBars";

function Radial() {
  const [isOpen, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleClick = (index: number) => {
    if (index === 0) {
      navigate("/projects");
    } else if (index === 1) {
      navigate("/about");
    } else if (index === 2) {
      navigate("/skills");
    }
  };

  return (
    <div className="App">
       <div style={{ position: "relative", left: "0px", top: "0px" }}>
        <motion.div
          style={{
            width: "64px",
            height: "64px",
            backgroundColor: "#46494c",
            borderRadius: "100%",
            position: "absolute",
            left: `calc(50% - ${64 / 2}px)`,
            top: `calc(50% - ${64 / 2}px)`,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          onClick={() => setOpen(!isOpen)}
          whileTap={{
            // scaleX: [1, 0.9, 1],
            // scaleY: [1, 1.1, 1]
            scaleX: 0.9,
            scaleY: 1.1
          }}
        ><Visualizer/>
        </motion.div>
        <AnimatePresence>
          {isOpen &&
            [1, 2, 3].map((item, index) => (
              <MenuItem
                key={index}
                index={index}
                itemsCount={3}
                size={60}
                radius={135}
                content={
                  index === 0 ? 
                    <Folder width="24px" height="24px" title={"Projekte"}/> : 
                    index === 1 ? 
                      <PersonCircle width="24px" height="24px" title={"Über mich"}/> :
                      <StatsBars width="24px" height="24px" title={"Skills"}/>
                }
                handleClick={() => handleClick(index)}
              />
            ))}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Radial;