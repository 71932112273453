import React from "react";
import Radial from "./Radial.tsx";
import "./Home.css";
import By from "./by";

function Home() {
  return (
    <div>
      <By />
      <div className="containerHome">
        <Radial />
      </div>
    </div>
  );
}

export default Home;
