import React from "react";
import { motion as m } from "framer-motion";
import Button from "./button";
import Bubbles from "./bubbles";
import ByAbt from "./byAbout";
import DogPic from "./res/dogWoods.jpeg";

const tileWidth =
  window.innerWidth * 0.8 < 330
    ? 330
    : window.innerWidth * 0.8 > 850
    ? 850
    : window.innerWidth * 0.8;

const mobileView = window.innerWidth < window.innerHeight;

function About() {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <ByAbt />
      <Button />
      <div
        style={{
          position: "absolute",
          marginTop: "150px",
          width: tileWidth,
          left: "50%",
          transform: mobileView
            ? "translateX(-50%) translateX(-5px)"
            : "translateX(-50%) translateX(-20px)",
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
        }}
      >
        <div
          className="glassTile"
          style={{
            width: tileWidth,
            opacity: 1,
            padding: mobileView ? "5px" : "20px",
            paddingTop: "15px",
            paddingBottom: "10px",
            borderRadius: "50px",
            left: `calc(50% - ${tileWidth / 2}px)`,
            display: "grid",
            gridTemplateColumns: mobileView ? "repeat(1)" : "repeat(2, 1fr)",
            alignItems: "center",
            justifyContent: "space-between",
            background:
              "linear-gradient(to top left, rgb(31, 31, 31, 0.46), rgb(61, 61, 61, 0.46))",
            backdropFilter: "blur(5px)",
            backgroundColor: "transparent",
          }}
        >
          <div>
            <p
              style={{
                inlineSize: mobileView ? "95%" : `calc(${tileWidth / 2.1}px)`,
                display: "flex",
                marginLeft: "10px",
                color: "#CCCCCC",
              }}
            >
              Mein Name ist Dominik. Im Sommer habe ich meinen Bachelor in
              Arbeits-, Organisations- und Personalpsycholgie absolviert. Ich
              habe eine grosse Leidenschaft fürs Lernen, Statistik und alles,
              was Technologie angeht.
            </p>
            <p
              style={{
                inlineSize: mobileView ? "95%" : `calc(${tileWidth / 2.1}px)`,
                display: "flex",
                marginLeft: "10px",
                color: "#CCCCCC",
              }}
            >
              In den letzten Jahren habe ich mich intensiv mit Statistik und der
              Angst vieler Studierenden vor diesem Thema auseinandergesetzt. In
              meiner Bachelorarbeit habe ich mich auf die Erforschung der
              Statistikangst konzentriert und ich habe mein eigenes
              Online-Nachhilfeangebot für Statistik entwickelt.
            </p>
            <p
              style={{
                inlineSize: mobileView ? "95%" : `calc(${tileWidth / 2.1}px)`,
                display: "flex",
                marginLeft: "10px",
                color: "#CCCCCC",
              }}
            >
              Mich fasziniert besonders die Interaktion zwischen Mensch und
              Maschine, weshalb ich mir in den letzten Monaten das Programmieren
              von Webseiten und Webapplikationen selbst beigebracht habe. Meine
              statistischen Kenntnisse und meine Kompetenz im Erheben von Daten
              ergänzen meine herzliche und kreative Persönlichkeit.
            </p>
            <p
              style={{
                inlineSize: mobileView ? "95%" : `calc(${tileWidth / 2.1}px)`,
                display: "flex",
                marginLeft: "10px",
                color: "#CCCCCC",
              }}
            >
              In meiner Freizeit verbringe ich viel Zeit mit meinem Hund Mio,
              organisiere Spieleabende mit Freunden oder beschäftige mich
              kreativ. Ich geniesse es auch, privat am Computer zu sitzen oder
              entspannt Musik zu hören.
            </p>
          </div>
          <img
            style={{
              display: "flex",
              height: "auto",
              width: mobileView ? "95%" : "85%",
              borderRadius: "50px",
              marginRight: mobileView ? "0px" : "10px",
              justifySelf: mobileView ? "center" : "end",
              marginBottom: mobileView ? "10px" : "0px",
            }}
            src={DogPic}
            alt=""
          />
        </div>
        <div>
          <Bubbles />
        </div>
      </div>
    </m.div>
  );
}

export default About;
