import React from "react";
import Button from "./button";
import TechSkills from "./skillsTech";
import LangSkills from "./skillsLang";
import OtherSkills from "./skillsOther";
import { motion as m } from "framer-motion";
import BySkills from "./bySkills";

const tileWidth =
  window.innerWidth * 0.95 < 330
    ? 330
    : window.innerWidth * 0.95 > 1800
    ? 1800
    : window.innerWidth * 0.95;

function Skills({ color, delay }) {
  const stackedView = window.innerWidth < 1800;

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <BySkills />
      <Button />
      <div
        style={{
          position: "absolute",
          width: tileWidth,
          display: "grid",
          gridTemplateColumns: stackedView
            ? "repeat(1, 1fr)"
            : "repeat(3, 1fr)",
          top: "150px",
          left: "50%",
          transform: "translateX(-50%)",
          gap: "37.5px",
        }}
      >
        <div style={{ marginBottom: "25px" }}>
          <LangSkills />
        </div>
        <div style={{ marginBottom: "25px" }}>
          <TechSkills />
        </div>
        <div style={{ marginBottom: "25px" }}>
          <OtherSkills />
        </div>
      </div>
    </m.div>
  );
}

export default Skills;
