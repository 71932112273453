import React from "react";
import { motion } from "framer-motion";
import "./Radial.css";

const MenuItem = (props: any) => {
  const RELATIVE_ANGLE: number = (360 / props.itemsCount) * props.index + 90;
  const RADTODEG_RATIO: number = Math.PI / 180;

  return (
    <motion.div
      className="menuTile"
      onClick={props.handleClick}
      whileTap={{
        // scaleX: [1, 0.9, 1],
        // scaleY: [1, 1.1, 1]
        scale: 0.8,
      }}
      style={{
        width: props.size + "px",
        height: props.size + "px",
        background: "radial-gradient(circle, rgba(221,174,238,1) 0%, rgba(148,233,231,1) 100%)",
        borderRadius: "100%",
        position: "absolute",
        left: `calc(50% - ${props.size / 2}px)`,
        top: `calc(50% - ${props.size / 2}px)`,
        userSelect: "none",
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
      }}
      initial={{
        // transform: `rotate(${
        //   props.itemsCount * props.index
        // }deg) translate(${80}px)`,

        x: `${
          Math.cos((RELATIVE_ANGLE + 45) * RADTODEG_RATIO) * (props.radius / 2)
        }px`,
        y: `${
          Math.sin(-(RELATIVE_ANGLE + 45) * RADTODEG_RATIO) * (props.radius / 2)
        }px`,

        opacity: 0,
      }}
      exit={{
        // x: `${
        //   Math.cos(RELATIVE_ANGLE * RADTODEG_RATIO) * (props.radius / 1.12)
        // }px`,
        // y: `${
        //   Math.sin(-RELATIVE_ANGLE * RADTODEG_RATIO) * (props.radius / 1.12)
        // }px`,
        opacity: 0,
      }}
      animate={{
        // transform: `rotate( ${
        //   (
        // }deg) translate(${80}px)`,

        x: `${Math.cos(RELATIVE_ANGLE * RADTODEG_RATIO) * props.radius}px`,
        y: `${Math.sin(-RELATIVE_ANGLE * RADTODEG_RATIO) * props.radius}px`,

        opacity: 0.75,
        // transition: {
        //   duration: 0.4
        // }
      }}
    >
      {props.content}
    </motion.div>
  );
};

export default MenuItem;
