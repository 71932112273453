import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { motion as m } from "framer-motion";

import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";

function Button() {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  return (
    <m.div
      style={{
        width: "50px",
        height: "50px",
        margin: "20px",
        opacity: 1,
        border: "1px solid",
        borderColor: "rgb(99, 99, 99, 0.5)",
        borderRadius: "50%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(to top left, rgb(31, 31, 31, 0.35), rgb(61, 61, 61, 0.35))",
        backdropFilter: "blur(5px)",
        cursor: "pointer",
        color: isHovered ? "white" : "rgb(177, 177, 177)",
        scale: isHovered ? "1.2" : "1",
        transition: "0.25s ease-in-out",
      }}
      onClick={handleClick}
      whileTap={{
        // scaleX: [1, 0.9, 1],
        // scaleY: [1, 1.1, 1]
        scale: 0.8,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
    >
      <ArrowBack width="24px" height="24px" />
    </m.div>
  );
}

export default Button;
